import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useFlexSearch } from "react-use-flexsearch"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-light-svg-icons"

const Search = ({
  searchTerm,
  minTermLength = 4,
  maxResults = 6,
  multilang = false,
  locale,
}) => {
  const [query, setQuery] = useState(null)
  const [searchDropdownActive, setSearchDropdownActive] = useState(false)

  const localSearchData = useStaticQuery(
    graphql`
      query {
        localSearchPages {
          index
          store
        }
      }
    `
  )
  const index = localSearchData.localSearchPages.index
  const store = localSearchData.localSearchPages.store

  const umlautMap = {
    ae: "ä",
    oe: "ö",
    ue: "ü",
  }

  const umlautMapReverse = {
    ä: "ae",
    ö: "oe",
    ü: "ue",
  }

  const replaceStr = (str) => {
    return str.replace(/ae|oe|ue/gi, (a) => {
      return umlautMap[a]
    })
  }

  const replaceStrReverse = (str) => {
    return str.replace(/ä|ö|ü/gi, (a) => {
      return umlautMapReverse[a]
    })
  }

  const quicklinks = [
    {
      title: "M&M Ratings",
      link: "service/ratings/",
    },
    {
      title: "M&M Office",
      link: "software/m-m-office/",
    },
    {
      title: "Volatium Modell",
      link: "data/volatium-modell/",
    },
    {
      title: "M&M Marktblick",
      link: "magazin/marktblick/",
    },
  ]

  let results = useFlexSearch(query, index, store)
  let finalResults = []
  if (multilang === true) {
    finalResults = results.filter((result) => {
      if (result.full_slug.split("/")[0] === locale) {
        return result
      } else {
        return ""
      }
    })
  } else {
    finalResults = results
  }
  return (
    <div className="w-full">
      <form
        className="flex flex-row w-full text-lg border-b border-primary"
        onSubmit={(e) => {
          e.preventDefault()
          setQuery(e.target[0].value)
        }}
      >
        <input
          name="query"
          className="flex-grow px-4 py-2 placeholder-secondary"
          id="searchBox"
          type="text"
          placeholder={searchTerm}
          onChange={(e) => {
            setQuery(
              e.target.value.length >= minTermLength &&
                replaceStrReverse(e.target.value)
            )
          }}
          onFocus={() => setSearchDropdownActive(true)}
        />
        <button className="hidden px-4 bg-white" type="submit">
          <span>
            <FontAwesomeIcon icon={faSearch} size="1x" className="" />
          </span>
        </button>
      </form>
      {searchDropdownActive && (
        <div className="overflow-auto text-lg bg-white max-h-screen-2/4">
          <ul className="">
            {finalResults.length > 0 ? (
              finalResults.map(
                (result, index) =>
                  index < maxResults && (
                    <li
                      className="flex justify-between w-full bg-gray-100 even:bg-white"
                      key={index}
                    >
                      <Link
                        className="inline-block px-4 py-2 hover:text-secondary"
                        link={`/${result.full_slug}`}
                      >
                        {result.name}
                      </Link>
                      <div className="inline-block px-4 py-2 text-gray-500 capitalize">
                        {replaceStr(result.full_slug.split("/")[0]).replace(
                          "-",
                          " "
                        )}
                      </div>
                    </li>
                  )
              )
            ) : (
              <>
                <li className="w-full bg-gray-100 even:bg-white">
                  <b className="inline-block px-4 py-2">Quicklinks:</b>
                </li>
                {quicklinks.map((item, index) => (
                  <li key={index} className="w-full bg-gray-100 even:bg-white">
                    <Link
                      className="inline-block px-4 py-2 hover:text-secondary"
                      link={`/${item.link}`}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Search
