import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import Navbar from "./header/Navbar"
import NavItem from "./header/NavItem"
import Submenu from "./header/Submenu"
import SearchMenu from "./header/SearchMenu"
import Login from "./header/Login"
import SearchButton from "./header/SearchButton"
import BurgerButton from "./header/BurgerButton"
import BurgerMenu from "./header/BurgerMenu"
import BurgerMenuItem from "./header/BurgerMenuItem"
import MetaNavItem from "./header/MetaNavItem"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const Header = ({ navigation, banner }) => {
  const [menuState, setmenuState] = useState(null)
  const [searchIsOpen, setSearchIsOpen] = useState(false)
  const [burgerMenuIsOpen, setBurgerMenuIsOpen] = useState(false)

  const menuClickHandler = (state) => {
    searchIsOpen && setSearchIsOpen(false)
    burgerMenuIsOpen && setBurgerMenuIsOpen(false)
    menuState === state ? setmenuState(null) : setmenuState(state)
  }

  const toggleSearch = () => {
    menuState !== null && setmenuState(null)
    burgerMenuIsOpen && setBurgerMenuIsOpen(false)
    setSearchIsOpen((value) => !value)
  }

  const toogleBurgerMenu = () => {
    menuState !== null && setmenuState(null)
    searchIsOpen && setSearchIsOpen(false)
    setBurgerMenuIsOpen((value) => !value)
  }

  const closeAllMenusHandler = () => {
    setmenuState(null)
    setBurgerMenuIsOpen(false)
    setSearchIsOpen(false)
  }

  useEffect(() => {
    if (window && document) {
      gsap.registerPlugin(ScrollTrigger)

      let mainHeader = document.querySelector(".header-main")

      if (mainHeader) {
        let mainOffset = mainHeader.offsetHeight
        let mainElement = document.querySelector("main")

        ScrollTrigger.create({
          start: "top -200",
          end: 99999,
          onEnter: () => {
            mainElement.style.paddingTop = `${mainOffset}px`
            mainHeader.classList.remove("header-main-fixed-show")
            mainHeader.classList.add("header-main-fixed")
            mainHeader.classList.add("header-main-fixed-active")
          },
          onLeaveBack: () => {
            mainElement.style.paddingTop = null
            mainHeader.classList.remove("header-main-fixed")
            mainHeader.classList.remove("header-main-fixed-active")
          },
        })

        // only show when scrolling up
        ScrollTrigger.create({
          start: "top -300",
          end: 99999,
          onUpdate: ({ direction }) => {
            if (direction == -1) {
              mainHeader.classList.add("header-main-fixed-show")
            } else {
              mainHeader.classList.remove("header-main-fixed-show")
            }
          },
        })
      }
    }
  }, [])

  return (
    <>
      {(menuState !== null || burgerMenuIsOpen || searchIsOpen) && (
        <Helmet
          htmlAttributes={{
            class: "overflow-hidden",
          }}
          bodyAttributes={{
            class: "theme-default font-body overflow-y-scroll",
          }}
        />
      )}
      <header id="top" className="relative h-24 bg-white header-main">
        <Navbar clickHandler={menuClickHandler}>
          {navigation?.map((item, index) => (
            <NavItem
              key={index}
              // title={item.title}
              // link={item.link}
              // uid={item._uid}
              item={item}
              clickHandler={menuClickHandler}
              menuState={menuState}
            />
          ))}
          <SearchButton clickHandler={toggleSearch} />
          <Login />
          <BurgerButton clickHandler={toogleBurgerMenu} />
        </Navbar>
        <SearchMenu
          searchState={searchIsOpen}
          clickHandler={menuClickHandler}
          banner={banner}
        />
        <Submenu
          menuState={menuState}
          navigation={navigation}
          clickHandler={menuClickHandler}
          closeAllMenusHandler={closeAllMenusHandler}
          banner={banner}
        />
        <BurgerMenu
          className="burgermenu-reveal"
          menuState={burgerMenuIsOpen}
          clickHandler={toogleBurgerMenu}
          banner={banner}
        >
          <ul className="block mb-4">
            {navigation?.map(
              (item, index) =>
                (item.component === "nav_group" ||
                  item.component === "nav_item") && (
                  <BurgerMenuItem
                    className="burgermenu-items-reveal"
                    key={index}
                    item={item}
                    // title={item.title}
                    // uid={item._uid}
                    clickHandler={menuClickHandler}
                    menuState={burgerMenuIsOpen}
                    closeAllMenusHandler={closeAllMenusHandler}
                  />
                )
            )}
          </ul>
          <ul>
            {navigation?.map((item) => {
              if (item?.component === "meta_navigation") {
                return item?.content?.map((item, index) => (
                  <MetaNavItem
                    className="burgermenu-items-reveal"
                    key={index}
                    title={item.title}
                    link={item.link}
                    closeAllMenusHandler={closeAllMenusHandler}
                    component={item.component}
                  />
                ))
              }
              return null
            })}
          </ul>
        </BurgerMenu>
      </header>
    </>
  )
}

export default Header
