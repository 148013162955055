import React from "react"
import Logo from "./Logo"

const Navbar = ({ children, clickHandler }) => {
  return (
    <div className="h-full px-4 mx-auto max-w-container lg:px-20">
      <div className="flex flex-row h-full flex-nowrap sm:flex-wrap -mx-grid">
        <Logo clickHandler={clickHandler} />
        <nav className="flex flex-row flex-no-wrap justify-end flex-1 px-grid -mx-grid">
          {children}
        </nav>
      </div>
    </div>
  )
}

export default Navbar
