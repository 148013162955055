import React from "react"
import ReactMarkdown from "react-markdown"

const Richtext = ({ text, flow, className = "" }) => {
  return (
    <>
      {text && text.length > 0 && (
        <ReactMarkdown
          children={text}
          className={`prose max-w-none ${className} ${
            flow === "2" ? "two-col" : flow === "3" ? "three-col" : ""
          }`}
        />
      )}
    </>
  )
}

export default Richtext
