import React, { useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { gsap } from "gsap"

const BurgerMenu = ({
  menuState,
  className,
  children,
  clickHandler,
  closeAllMenusHandler,
  banner,
}) => {
  useEffect(() => {
    if (menuState) {
      let tlOpen = gsap.timeline()
      tlOpen.to(
        ".burgermenu-reveal",
        {
          ease: "power1.out",
          duration: 0.25,
          opacity: 1,
          x: 0,
        },
        0
      )
    } else {
      let tlClose = gsap.timeline()
      tlClose.to(
        ".burgermenu-reveal",
        {
          opacity: 0,
          x: "100%",
        },
        0
      )
    }
  }, [menuState])

  return (
    <div
      className={`burgermenu absolute w-full ${
        banner ? "h-fullvh-banner" : "h-fullvh"
      } sm:w-auto top-0 right-0 z-50 flex bg-gray-100 transform transition-all ease-out duration-500 translate-x-full overflow-y-auto ${className}`}
    >
      <div className="w-full h-full px-16 pt-5 pb-8">
        <button
          className="block px-4 py-2 ml-auto -mr-16 text-xl lg:-mr-12 hover:text-secondary"
          onClick={() => clickHandler()}
        >
          <span className="inline-block w-6 h-6">
            <FontAwesomeIcon icon={faTimes} size="1x" />
          </span>
        </button>
        {children}
      </div>
    </div>
  )
}

export default BurgerMenu
