import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleUp } from "@fortawesome/pro-light-svg-icons"

const ReturnTopButton = ({}) => {
  return (
    <div className="sticky flex z-[1000] bottom-4 w-full">
      <div className="w-full relativ">
        <a
          className="absolute block w-8 text-center text-white transition-transform duration-200 transform -translate-y-full hover:scale-110 right-4 bg-primary"
          href="#top"
          title="Zum Seitenanfang"
        >
          <FontAwesomeIcon icon={faAngleUp} size="2x" className="" />
        </a>
      </div>
    </div>
  )
}

export default ReturnTopButton
