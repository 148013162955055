import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-light-svg-icons"

const SearchButton = ({ clickHandler }) => {
  return (
    <div>
      <div className="mt-5 mb-auto">
        <button
          className="w-auto px-3 py-2 text-xl cursor-pointer md:block hover:text-secondary"
          onClick={() => clickHandler()}
        >
          <span className="inline-block w-6 h-6">
            <FontAwesomeIcon icon={faSearch} size="1x" className="" />
          </span>
        </button>
      </div>
    </div>
  )
}

export default SearchButton
