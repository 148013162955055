import React from "react"
import { Link } from "gatsby"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import logo from "../../../../../images/mm-logo.svg"
import logoMobile from "../../../../../images/mm-logo-noclaim.svg"

const Logo = ({ clickHandler }) => {
  let isStart = null

  const checkStart = ({ isCurrent }) => {
    isStart = isCurrent
  }

  return (
    <span
      className="mt-6 mb-auto lg:mt-5 px-grid"
      onClick={() => isStart && clickHandler(null)}
    >
      <Link getProps={checkStart} to="/">
        <Image
          className="hidden h-16 lg:inline-block"
          image={{
            filename: logo,
            alt: "Morgen und Morgen Logo",
            title: "Morgen und Morgen Logo",
          }}
        />
        <Image
          className="inline-block w-full h-auto sm:h-8 sm:w-auto lg:hidden"
          image={{
            filename: logoMobile,
            alt: "Morgen und Morgen Logo",
            title: "Morgen und Morgen Logo",
          }}
        />
      </Link>
    </span>
  )
}

export default Logo
