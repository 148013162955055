import React, { useRef } from "react"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import HeadlineReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/HeadlineReveal"
import TextReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/TextReveal"

const HeadlineEnhanced = ({
  kicker,
  headline,
  headlineLevel = "h2",
  subheadline,
  className,
  tagName,
  ...props
}) => {
  let hasKicker = kicker && kicker.length > 0 ? true : false
  let hasHeadline = headline && headline.length > 0 ? true : false
  let hasSubheadline = subheadline && subheadline.length > 0 ? true : false

  return (
    <>
      {(hasKicker || hasHeadline || hasSubheadline) && (
        <div className={className}>
          {hasKicker && (
            // <div
            //   className={`px-2 py-1.5 sm:px-3 sm:py-2 ${
            //     hasHeadline || hasSubheadline ? "mb-4" : ""
            //   }`}
            // >
            <div
              className={`py-1 ${hasHeadline || hasSubheadline ? "mb-4" : ""}`}
            >
              <TextReveal
                className={`kicker kicker-${headlineLevel} ${
                  hasHeadline || hasSubheadline ? "mb-4" : ""
                }`}
                tagName="span"
              >
                {kicker}
              </TextReveal>
            </div>
          )}

          {hasHeadline && (
            <HeadlineReveal className={`${hasSubheadline ? "mb-4" : ""}`}>
              <Headline
                headline={headline}
                headlineLevel={headlineLevel}
                tagName={tagName}
              />
            </HeadlineReveal>
          )}
          {hasSubheadline && (
            <TextReveal
              className={`subheadline subheadline-${headlineLevel}`}
              tagName="span"
            >
              {subheadline}
            </TextReveal>
          )}
        </div>
      )}
    </>
  )
}

export default HeadlineEnhanced
