import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/pro-light-svg-icons"

const BurgerButton = ({ clickHandler }) => {
  return (
    <div className="mt-5 mb-auto">
      <button
        className="w-auto py-2 pl-3 pr-4 text-xl cursor-pointer md:block hover:text-secondary"
        onClick={() => clickHandler()}
      >
        <span className="inline-block w-6 h-6">
          <FontAwesomeIcon icon={faBars} size="1x" />
        </span>
      </button>
    </div>
  )
}

export default BurgerButton
