import React, { Fragment } from "react"

import SubmenuContent from "./SubmenuContent"

const Submenu = ({
  menuState,
  navigation,
  clickHandler,
  closeAllMenusHandler,
  banner,
}) => {
  return (
    <div
      className={`submenu absolute left-0 z-50 block w-full lg:top-24 ${
        banner
          ? "h-fullvh-banner lg:h-fullvh-banner-submenu"
          : "h-fullvh lg:h-fullvh-submenu "
      } bg-gray-50 top-0  transform transition-all ease-out duration-500 ${
        menuState !== null ? "opacity-100 visible" : "opacity-0 invisible"
      }`}
    >
      {navigation?.map(
        (nav, index) =>
          nav._uid === menuState && (
            <Fragment key={index}>
              <SubmenuContent
                nav={nav}
                index={index}
                clickHandler={clickHandler}
                closeAllMenusHandler={closeAllMenusHandler}
              />
            </Fragment>
          )
      )}
    </div>
  )
}

export default Submenu
