import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const BannerBlock = ({ banner }) => {
  if (!banner) return null
  return (
    <div className="relative h-12 bg-blue">
      <div className="px-4 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className=" sm:text-center xl:px-16">
          <p className="font-medium text-white">
            <span className="block sm:ml-2 sm:inline-block">
              <Link
                link={banner.link}
                className="text-sm line-clamp-1 sm:text-base hover:underline"
              >
                <span className="hidden sm:inline-block">{banner.title}</span>
                <span className="inline-block sm:hidden">
                  {banner.titleshort?.length > 0
                    ? banner.titleshort
                    : banner.title}
                </span>
                <span className="ml-1" aria-hidden="true">
                  &rarr;
                </span>
              </Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default BannerBlock
