import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/pro-light-svg-icons"

const NavItem = ({ item, clickHandler, menuState }) => {
  if (item.component === "nav_group") {
    return (
      <div className="mt-4 mb-auto">
        <button
          onClick={() => clickHandler(item._uid)}
          className="hidden w-full px-3 py-4 leading-snug uppercase cursor-pointer lg:block lg:w-auto font-display hover:text-secondary"
        >
          {item.title}
          <span className="inline-block w-6 h-6 text-lg leading-4">
            <FontAwesomeIcon
              icon={faAngleDown}
              size="1x"
              className={` transition duration-500 ${
                menuState === item._uid ? "transform -rotate-180" : ""
              }`}
            />
          </span>
        </button>
      </div>
    )
  } else if (item.component === "nav_item") {
    return (
      <div className="mt-4 mb-auto">
        <Link
          link={item.link}
          className="hidden w-full px-3 py-4 leading-snug uppercase cursor-pointer lg:block lg:w-auto font-display hover:text-secondary"
        >
          {item.title}
        </Link>
      </div>
    )
  } else {
    return <></>
  }
}

export default NavItem
