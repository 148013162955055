import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const MetaNavItem = ({ component, title, link, className, closeAllMenusHandler }) => {
  return (
    <>
      {component === "nav_spacer" ? (
        <span className="block h-1 transform translate-y-12 opacity-0 submenu-col2-item-reveal" />
      ) : (
        <li className={`opacity-0 transform translate-x-full ${className}`}>
          <Link
            className="block mb-4 uppercase hover:text-secondary"
            link={link}
            onClick={closeAllMenusHandler}
          >
            {title}
          </Link>
        </li>
      )}
    </>
  )
}

export default MetaNavItem
