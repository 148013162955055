import React, { useEffect } from "react"
import { gsap } from "gsap"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const BurgerMenuItem = ({ item, clickHandler, className, menuState, closeAllMenusHandler }) => {
  useEffect(() => {
    if (menuState) {
      let tlOpen = gsap.timeline()

      tlOpen.to(
        ".burgermenu-items-reveal",
        {
          ease: "power1.out",
          duration: 0.75,
          opacity: 1,
          x: 0,
          stagger: 0.05,
        },
        0
      )
    } else {
      let tlClose = gsap.timeline()
      tlClose.to(
        ".burgermenu-items-reveal",
        {
          opacity: 0,
          x: "100%",
          stagger: 0.05,
        },
        0
      )
    }
  }, [menuState])
  if (item.component === "nav_group") {
    return (
      <li className={`opacity-0 transform translate-x-full ${className}`}>
        <button
          className="w-full mb-4 text-lg leading-snug text-left uppercase cursor-pointer font-display hover:text-secondary"
          onClick={() => clickHandler(item._uid)}
        >
          {item.title}
        </button>
      </li>
    )
  } else if (item.component === "nav_item") {
    return (
      <li className={`opacity-0 transform translate-x-full ${className}`}>
        <Link
          link={item.link}
          className="inline-block w-full mb-4 text-lg leading-snug text-left uppercase cursor-pointer font-display hover:text-secondary"
          onClick={closeAllMenusHandler}
        >
          {item.title}
        </Link>
      </li>
    )
  } else {
    return <></>
  }
}

export default BurgerMenuItem
