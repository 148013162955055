import { useState, useEffect, useCallback } from 'react';

declare global {
	interface Window {
		Cookiebot: any;
	}
}

function isConsentGranted(serviceId: string): boolean {
  // serviceId can be loaded from storyblok and may be empty
  // If it is empty, we assume that no consent is required
  if (serviceId == '') return true;
  // Map old 'klaro' serviceIds to cookiebot ids
  serviceId = (serviceId === 'youtubevideo' || serviceId === 'vimeovideo')
    ? 'marketing'
    : serviceId;
  const consent = window.Cookiebot?.consent ?? {}
  return (consent[serviceId] ?? false) === true;
}

/**
 * Cookiebot provides only 4 service groups. It doesn't seem to be possible to grant or deny
 * consent for a single service like Youtube individually
 */
export enum CookiebotServiceId {
  NECESSARY = 'necessary',
  PREFERENCES = 'preferences',
  MARKETING = 'marketing',
  STATISTICS = 'statistics',
}

export type CookiebotConsentState = 'loading' | 'accepted' | 'denied';

/**
 * Get cookiebot consent status
 *
 * Documentation:
 * https://support.cookiebot.com
 */
export function useCookiebotConsent(serviceId: string): CookiebotConsentState {
  const [consentState, setConsentState] = useState<CookiebotConsentState>('loading');
  const updateState = useCallback(() => {
    setConsentState(isConsentGranted(serviceId) ? 'accepted' : 'denied');
  }, [serviceId]);

  useEffect(() => {
    if (window.Cookiebot?.consent) {
      updateState();
    }
    window.addEventListener('CookiebotOnLoad', updateState);
    window.addEventListener('CookiebotOnAccept', updateState);
    window.addEventListener('CookiebotOnDecline', updateState);

    return () => {
      window.removeEventListener('CookiebotOnLoad', updateState);
      window.removeEventListener('CookiebotOnAccept', updateState);
      window.removeEventListener('CookiebotOnDecline', updateState);
    };
  }, [updateState]);

  return consentState;
}
