import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLinkedinIn,
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"

const Footer = ({ navigation }) => {
  return (
    <footer className="pt-16 pb-8 text-white bg-primary">
      {/* Footer Head */}
      <section className="">
        <div className="mx-auto mb-8 block-w-container">
          <div className="flex flex-row flex-wrap items-end sm:justify-end">
            {/* Instagram */}
            <Link
              link="https://www.instagram.com/morgenundmorgen"
              className="mb-4 mr-4 text-xl transition-all duration-200 ease-in transform bg-white border-2 border-white btn text-secondary hover:scale-110 hover:shadow-lg"
            >
              <FontAwesomeIcon icon={faInstagram} size="1x" className="" />
            </Link>
            {/* Facebook */}
            <Link
              link="https://www.facebook.com/morgenundmorgen"
              className="mb-4 mr-4 text-xl transition-all duration-200 ease-in transform bg-white border-2 border-white btn text-secondary hover:scale-110 hover:shadow-lg"
            >
              <FontAwesomeIcon icon={faFacebookF} size="1x" className="" />
            </Link>
            {/* Youtube */}
            <Link
              link="https://www.youtube.com/channel/UCfUdYYhuBvJOpynWQpO6XcA"
              className="mb-4 mr-4 text-xl transition-all duration-200 ease-in transform bg-white border-2 border-white btn text-secondary hover:scale-110 hover:shadow-lg"
            >
              <FontAwesomeIcon icon={faYoutube} size="1x" className="" />
            </Link>
            {/* LinkedIn */}
            <Link
              link="https://de.linkedin.com/company/morgen-&-morgen-gmbh"
              className="mb-4 mr-4 text-xl transition-all duration-200 ease-in transform bg-white border-2 border-white btn text-secondary hover:scale-110 hover:shadow-lg"
            >
              <FontAwesomeIcon icon={faLinkedinIn} size="1x" className="" />
            </Link>
            <Link
              link="/support"
              className="mb-4 text-xl transition-all duration-200 ease-in transform bg-white border-2 border-white btn text-secondary hover:scale-110 hover:shadow-lg"
            >
              Kontakt
            </Link>
          </div>
        </div>
      </section>
      {/* Footer Nav */}
      <section className="uppercase">
        <div className="mx-auto block-w-container">
          <div className="flex flex-row flex-wrap -mx-grid">
            {navigation?.map((nav, index) =>
              nav.component === "nav_group" ? (
                <div
                  className="w-full mb-12 px-grid sm:w-1/2 lg:w-1/4"
                  key={index}
                >
                  <ul>
                    {nav.content?.map((item, index) => (
                      <li key={index}>
                        <Link
                          className={
                            item.primary
                              ? "inline-block mb-2 text-lg font-bold text-primary hover:text-primary-hover"
                              : "inline-block mb-2 hover:underline"
                          }
                          link={`/${item.link.cached_url}`}
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : nav.component === "meta_navigation" ? (
                <div
                  className="w-full mb-12 px-grid sm:w-1/2 lg:w-1/4"
                  key={index}
                >
                  <ul>
                    {nav.content?.map((item, index) => (
                      <li key={index}>
                        <Link
                          className={`inline-block mb-2 hover:underline ${
                            index + 1 !== nav.content.length && "mr-4"
                          }`}
                          link={item.link}
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
                    <li key={index}>
                      <Link
                        className={`inline-block mb-2 hover:underline ${
                          index + 1 !== nav.content.length && "mr-4"
                        }`}
                        link="/"
                        onClick={(e) => {
                          e.preventDefault();
                          window.Cookiebot?.show();
                        }}
                      >
                        Cookie-Konfiguration
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : (
                nav.component === "nav_text_content" && (
                  <div
                    className="w-full mb-12 px-grid sm:w-1/2 lg:w-1/4"
                    key={index}
                  >
                    <Richtext className="prose-invert" text={nav.text} />
                  </div>
                )
              )
            )}
          </div>
          <div className="w-full">
            <span>© {new Date().getFullYear()} Morgen & Morgen GmbH</span>
          </div>
        </div>
      </section>
    </footer>
  )
}

export default Footer
