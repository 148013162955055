import React from "react"

const Headline = ({ headline, headlineLevel, tagName, ...props }) => {
  const HTag =
    tagName && tagName.length > 0
      ? tagName
      : headlineLevel && headlineLevel.length > 0
      ? headlineLevel
      : "h2"
  return (
    <HTag
      className={`headline headline-${headlineLevel} relative inline-block py-2`}
      {...props}
      dangerouslySetInnerHTML={{
        __html: headline.replace(/–/i, "<br />"),
      }}
    />
  )
}
export default Headline
