import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignIn } from "@fortawesome/pro-light-svg-icons"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const Login = () => {
  return (
    <div className="mt-5 mb-auto lg:mt-6">
      <Link
        className="block uppercase transition-all duration-200 ease-in leading-snug w-auto font-display border border-secondary text-secondary px-2 py-1.5 mx-3 hover:text-secondary transform hover:scale-105 hover:shadow-lg"
        link="/login"
      >
        <span className="inline-block">Login</span>
      </Link>
    </div>
  )
}

export default Login
